import React, { useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import Layout from '../components/Layout';
import ProductTabs from '../components/Products/ProductTabs';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import CustomLink from '../components/Link';
import SEO from '../components/Seo';
import Protect from 'react-app-protect';

const SingleProductComparisonTemplate = (props) => {
  if (!props) return null;
  const node_locale = props.pageContext.node_locale;
  const translation = props.pageContext.translation;

  const pageContent = props.pageContext.data;
  const footerContent = props.pageContext.footer.node;
  const headerContent = props.pageContext.header.node;
  const singleProduct = props.pageContext.singleProduct;

  const localSeo = props.pageContext.data.seo && props.pageContext.data.seo;
  const slug = props.pageContext.data.slug;

  return (
    <>
      {typeof window !== 'undefined' && (
        <Protect sha512="5F8A01670BDECBAFD1D1D5D23E3CB2205B65FE9BABA76542EDB8B36EDD69215E8042015790D3ED2C1A6E3CB949966991C8BECF34EEB02ABA9FF1EDE860EB3E38">
          <Layout
            themeColor="mode--light"
            currentLocation={props.path}
            currentNodeLocale={node_locale}
            footerContent={footerContent}
            headerContent={headerContent}
            translation={translation}
          >
            {localSeo && <SEO seoData={localSeo} url={props.location && slug} />}

            <section className="section">
              <div className="container">
                <div className="section__heading ">
                  <ReactMarkdown
                    source={pageContent.heading.childMarkdownRemark.rawMarkdownBody}
                    disallowedTypes={['paragraph']}
                    unwrapDisallowed={true}
                  />
                </div>
                {pageContent.singleProductFeaturesHighlight &&
                  pageContent.singleProductFeaturesHighlight.map((feature, index) => {
                    return (
                      <div key={index} className="feature">
                        <div className="feature__image">
                          <img
                            src={feature.image.file.url}
                            alt={feature.image.description ? feature.image.description : ''}
                          />
                        </div>
                        <div className="feature__text">
                          {documentToReactComponents(feature.content.json)}
                          <div className="text-center lg:text-left">
                            {feature.link && <CustomLink link={feature.link} />}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </section>
            <section className="section">
              <div className="container">
                <div className="section__heading ">
                  <ReactMarkdown
                    source={pageContent.productComparisonHeading.childMarkdownRemark.rawMarkdownBody}
                    disallowedTypes={['paragraph']}
                    unwrapDisallowed={true}
                  />
                </div>
                <div className="block lg:hidden">
                  <ProductTabs productsInfo={singleProduct} />
                </div>
                <div className="product-comparison">
                  <ProductTabs
                    productsInfo={pageContent.products}
                    singleProductIdentifier={pageContent.productIdentifier}
                    productCompare={true}
                    featuresHeading={pageContent.featuresHeading}
                  />
                </div>
              </div>
            </section>
          </Layout>
        </Protect>
      )}
    </>
  );
};

export default SingleProductComparisonTemplate;
